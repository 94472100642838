import React, { useState } from "react";
import Dropdown from "./Dropdown";
import DropdownArrow from "../icons/DropdownArrow";
import Menu from "../icons/Menu";
import Logo from "../assets/Logo";
import Linkedin from "../icons/Linkedin";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

import { CSSTransition } from "react-transition-group";

const Header = (data) => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  function toggleSidebar() {
    setSidebarVisibility(!isSidebarVisible);
  }

  return (
    <>
      <ul id="SocialList">
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/craftsman-duty-free/">
          <Linkedin />
        </a>
      </ul>
        <nav id={data.id} className="flex-col">
          {!isSidebarVisible && <Menu handleClick={toggleSidebar} />}
          <CSSTransition
            in={isSidebarVisible}
            timeout={300}
            classNames="sidebar-transition"
            unmountOnExit
          >
          <Sidebar toggleSidebar={toggleSidebar} />
          </CSSTransition>
          <div className="flex-row container-xl mx-auto">
            <Link className="subnav" to="/services">
              Services
            </Link>
            <div className="dropdown subnav">
      
              <Link to="#">
                Portfolio
                <span className="dropdown-arrow-span">
                  <DropdownArrow fill={"#ffff"} />
                </span>
              </Link>
              <div className="dropdown-content">
                <Dropdown />
              </div>
            </div>
            <Link className="logo-container flex-row" to="/">
              <Logo color={"#ffff"} />
            </Link>
            <Link className="subnav" to="/about">
              About
            </Link>
            <Link className="subnav" to="/contact">
              Contact
            </Link>
          </div>
        </nav>

      <Outlet />
    </>
  );
};

export default Header;
