import React, { useEffect } from "react";
import Swiper from "swiper";
import Slides from "../../data/projectShowcaseData.json";
import { Autoplay } from 'swiper/modules';

import 'swiper/swiper-bundle.css';


const HomepagePortfolio = () => {
  const slidesCount = Slides.slides.length;

  useEffect(() => {
    const swiperProjects = new Swiper(".project-swiper", {
      slidesPerView: "auto",
      spaceBetween: 50,
      centeredSlides: true,
      centeredSlidesBounds: true,
      loop: true,
      autoplay: { delay: 2000 ,disableOnInteraction: true },
      modules: [Autoplay]

    });
  }, []);
  return (
    <div className="homepage-portfolio flex-col">
      <div className="homepage-portfolio-header">
        <span className="small-header fs-3">Recent</span>
        <br />
        <h2 className="section-name fs-6">Showcase</h2>
      </div>
      <div className="project-swiper-container">
        <div className="project-swiper">
          <div className="swiper-wrapper">
            {Slides.slides.map((item, idx) => {
              return (
                <div className="swiper-slide" key={idx}>
                  <div className="rounded-square flex-col">
                    <img loading="lazy" src={item.src} alt={item.alt} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepagePortfolio;
