import React, { useState, useEffect, useRef } from "react";
import HomepageServices from "../components/homepage/HomepageServices-Copy";
import HomepageAbout from "../components/homepage/HomepageAbout";
import HomepagePortfolio from "../components/homepage/HomepagePortfolio";
import BrandCarousel from "../components/global/BrandCarousel";
import ContactForm from "../components/global/ContactForm";
import Wave from "../components/assets/WaveTop";
import HomepageHero from "../components/homepage/HomepageHero";
import Footer from "../components/global/Footer";
import Layout from "./Layout";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>Craftsman - Home - International Retail Specialists</title>
        <meta
          name="description"
          content="Craftsman offers a comprehensive turnkey solution for clients, including services such as 3D design, manufacturing, installations/removals, afterworks & storage."
        />
        <meta
          property="og:title"
          content="Craftsman - Services - International Retail Specialists"
        />
        
        <meta
          property="og:description"
          content="Craftsman - International Retail Specialists - For four decades, Craftsman has been at the forefront of crafting bespoke luxury retail furniture for retail markets, globally."
        />

        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />

        <meta property="og:url" content="https://craftsman-llc.com/" />
        
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="top-content">
        <div className="relative">
          <div className="underlay"></div>
          <div className="underlay-color"></div>
          <div className="">
            <Layout />
          </div>
          <HomepageHero />
        </div>
      </div>
      {/* <div id="content-ontop" className="bg"></div> */}
      <div className="container-xl mx-auto mt-10 sm:mt-5 mb-15 homepage-portfolio-container flex-col">
        <HomepagePortfolio />
      </div>

      <div className="background-now">
        <div className="background-now-underlay"></div>
        <div className="background-now-color"></div>
        <div className="background-now-overlay">
          <div className="mt-10 homepage-services-container flex-col">
            <div className="container-xl py-10 mx-auto">
              <HomepageServices />
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-about-section my-5 ">
        <div className="container-xl mx-auto py-5 lex-col">
          <HomepageAbout />
        </div>
      </div>

      <div className="brand-container mb-5">
        <BrandCarousel />
      </div>
      <div className="homepage-contact-container my-10 container-xl mx-auto">
        <ContactForm />
      </div>
      <Footer />
    </>
  );
};

export default Home;
