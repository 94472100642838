import "./Css/main.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Services from "./pages/Services";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import ScrollToTop from "./components/global/ScrollToTop";

import PopUp from "./pages/portfolio/PopUp";
import CaseStudy from "./pages/portfolio/case-study/[name]";
import Podiums from "./pages/portfolio/Podiums";
import HPP_DressUp from "./pages/portfolio/HPP_DressUp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/retail-displays" element={<Podiums />} />
        <Route path="/hpp-dressups" element={<HPP_DressUp />} />
        <Route path="/portfolio/:title" element={<PopUp />} />
        <Route path="/portfolio/case-study/:name" element={<CaseStudy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);
