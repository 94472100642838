import React, { useState } from "react";
import ContactForm from "../components/global/ContactForm";
import Layout from "./Layout";
import { Helmet } from "react-helmet";


const Contact = () => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>
          Craftsman - Contact - International Retail Specialists
        </title>
        <meta
          name="description"
          content="Reach out with questions or comments via our form or email us at craftman@emirates.net.ae. Call us at +971 67457 146."
        />
        
        <meta
          property="og:title"
          content="Craftsman - Contact - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Reach out with questions or comments via our form or email us at craftman@emirates.net.ae. Call us at +971 67457 146."
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta
          property="og:url"
          content="https://craftsman-llc.com/contact"
        />
        <meta property="og:type" content="website" />

        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="contact-page">
        <Layout />
        <div className="container-xl pt-5 mx-auto">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
