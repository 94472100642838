import React from "react";

const ArrowRight = () => {
  return (
    <div className="arrow-container">
      <svg
        fill="#fff"
        width="40px"
        height="40px"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className="arrow-right" // Add a class to the SVG
      >
        <path d="M604.7 759.2l61.8-61.8L481.1 512l185.4-185.4-61.8-61.8L357.5 512z" />
      </svg>
    </div>
  );
};

export default ArrowRight;
