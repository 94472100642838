import React, { useState } from "react";
import Close from "../icons/Close";
function HeroImageCarousel() {
  const [active, setActive] = useState("slider3");
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  // const [wideImage, setWideImage] = useState(false);

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const id = e.dataTransfer.getData("text/plain");
    setActive(id);
  };

  const handleHover = (id) => {
    setActive(id);
  };

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const removeModel = (bool) => {
    setModel(bool);
    setTempImgSrc("");
  };

  return (
    <div
      className="hero-image-carousel flex flex-row gap-4"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >

      <div
        onClick={() => removeModel(false)}
        className={model ? "model open" : "model"}
      >
        <img loading="lazy" src={tempImgSrc} />
        <div onClick={() => removeModel(false)}>
          <Close fill={"#fff"} />
        </div>
      </div>

      <div
        className={`carousel-container ${active === "slider1" ? "active" : ""}`}
        onMouseEnter={() => handleHover("slider1")}
      >
        <img
          id="slider1"
          onClick={(e) => getImg("/images/heroImages/1.webp")}
          src="/images/heroImages/1.webp"
          draggable="true"
          onDragStart={handleDragStart}
        />
      </div>
      <div
        className={`carousel-container ${active === "slider2" ? "active" : ""}`}
        onMouseEnter={() => handleHover("slider2")}
      >
        <img
          id="slider2"
          onClick={(e) => getImg("/images/heroImages/2.webp")}
          src="/images/heroImages/2.webp"
          draggable="true"
          onDragStart={handleDragStart}
        />
      </div>
      <div
        className={`carousel-container ${active === "slider3" ? "active" : ""}`}
        onMouseEnter={() => handleHover("slider3")}
      >
        <img
          id="slider3"
          onClick={(e) => getImg("/images/heroImages/3.webp")}
          src="/images/heroImages/3.webp"
          draggable="true"
          onDragStart={handleDragStart}
        />
      </div>
      <div
        className={`carousel-container ${active === "slider4" ? "active" : ""}`}
        onMouseEnter={() => handleHover("slider4")}
      >
        <img loading="lazy"  
          id="slider4"
          onClick={(e) => getImg("/images/heroImages/4.webp")}
          src="/images/heroImages/4.webp"
          draggable="true"
          onDragStart={handleDragStart}
        />
      </div>
      <div
        className={`carousel-container ${active === "slider5" ? "active" : ""}`}
        onMouseEnter={() => handleHover("slider5")}
      >
        <img
          id="slider5"
          onClick={(e) => getImg("/images/heroImages/5.webp")}
          src="/images/heroImages/5.webp"
          draggable="true"
          onDragStart={handleDragStart}
        />
      </div>
    </div>
  );
}

export default HeroImageCarousel;
