import React from "react";
import Slides from "../data/carouselData.json";
import Footer from "../components/global/Footer";
import ServicesSection from "../components/services-page/ServicesSection";
import Layout from "./Layout";
import ContactForm from "../components/global/ContactForm";
import { Helmet } from "react-helmet";

const Services = () => {
  const designsContent =
    "Our design team possesses a profound comprehension of the intricacies and prerequisites associated with a project's installation, considering both the physical and cultural aspects. Grounded in our expertise of the local region and its unique constraints, our 3D designs, CAD, and technical drawings are crafted to provide our clients with suitable solutions, enabling them to transform their ideas into reality. Collaboratively, we develop prototypes that offer a tangible visualization, allowing ample time for adjustments before the project reaches its finalization stage. ";

  const manufacturingContent =
    "All manufacturing takes place in-house, leveraging a team that offers flexibility to produce the complete spectrum of a project. Our commitment to high-quality, purpose-built production is evident in every aspect. Utilizing premium materials and latest technologies, we specialise in crafting bespoke luxury retail furniture and eye-catching promotional displays.";

  const installationContent =
    "The installation phase is pivotal to the success of any project. Our highly trained and experienced teams provide timely delivery and installations in alignment with our client's expectations. Utilising our extensive network spanning across the EMEA, leverage our vast connections and experience to effectively service multiple regions on behalf of our clients. Supported by Craftsman excellent administrative team, which handle all the necessary procurement, documentation, certification and logistical tasks involves from start to finish. Alleviating stress for our clients and ensuring seamless and trustworthy process throughout the project's entire timeline.";

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>Craftsman - International Retail Specialists</title>
        <meta
          name="description"
          content="Craftsman - International Retail Specialists - For four decades, Craftsman has been at the forefront of crafting bespoke luxury retail furniture for retail markets, globally."
        />
        <meta
          property="og:title"
          content="Craftsman - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Craftsman - International Retail Specialists - For four decades, Craftsman has been at the forefront of crafting bespoke luxury retail furniture for retail markets, globally."
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta property="og:url" content="https://craftsman-llc.com/" />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div id="servicesPage" className="flex-col">
        <Layout />
        <div className="container-xl gap-8 flex-col mx-auto my-5">
          <div>
            <h3 className="fs-5">In House Facilities & Capabiltiies</h3>
            <p className="fs-0  justify">
              Complete turnkey solutions, encompassing every aspect from
              conceptual design to project implementation. Our commitment to
              transparency and open communication keeps our clients actively
              involved in the decision-making process and finalisation of
              finishes. Beyond being a service provider, we become your trusted
              partner who are fully dedicated to the success of your projects.
            </p>
          </div>
          <hr className="mb-3 w-50 ml-auto" />
          <div className="flex-col gap-10">
            <div id="servicesTop" className="pt-5">
              <ServicesSection
                slideData={Object.values(Slides.slides.design)}
                pContent={designsContent}
                serviceName={"Design Development"}
                id={"designSection"}
                icon={"/icons/DesignIcon.png"}
                class={"services-container flex-col"}
              />
            </div>
            <div id="servicesMiddle" className="flex-col">
              <ServicesSection
                slideData={Object.values(Slides.slides.manufacturing)}
                serviceImage={""}
                icon={"/icons/DesignIconWhite.png"}
                pContent={manufacturingContent}
                serviceName={"Manufacturing"}
                id={"manufactureSection"}
                class={"services-container flex-col"}
              />
            </div>

            <div id="servicesBottom" className="flex-col">
              <ServicesSection
                slideData={Object.values(Slides.slides.installation)}
                pContent={installationContent}
                serviceName={"Installations / Removals"}
                id={"installationSection"}
                icon={"/icons/DesignIcon.png"}
                class={"services-container flex-col"}
              />
            </div>
          </div>

          <div className="mb-5">
            <div style={{ color: "rgba(102, 113, 144, 0.9960784314)" }}>
              <span className="small-header fs-2">Afterworks & Storage</span>
              <br />
              <h3 className="fs-5 section-name">We Will Stay In Touch!</h3>
            </div>
            <p className="fs-0  justify">
              Our team remains readily available for ongoing support, including
              maintenance and updates. We offer upkeep contracts for all our
              fixtures, deploying out team as an on-call service for any
              necessary touch-ups.
              <br />
              <br />
              Beyond projects completion, we possess the capability to store and
              maintain requested furniture, allowing for re-use, refurbishment,
              or adaption into new designs. Embracing our commitment to
              sustainable production by reducing wastage of raw materials and
              sourcing environmentally friendly materials. Helping to contribute
              to a greener and conscious future in collaboration with our
              clients.
            </p>
          </div>
          <hr className="mb-3 " />
          <ContactForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
