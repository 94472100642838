import React, { useState } from "react";
import Layout from "../Layout";
import Footer from "../../components/global/Footer";
import podiumsData from "../../data/hppDressUpData.json";
import PodiumSection from "../../components/global/PodiumSection";
import ImageGrid from "../../components/global/ImageGrid";
import { Helmet } from "react-helmet";
import Close from "../../components/icons/Close";

const images = [
  "/images/hppDressUpImages/hppbottom (3).webp",
  "/images/hppDressUpImages/hppbottom (4).webp",
  "/images/hppDressUpImages/hppbottom (2).webp",
  "/images/hppDressUpImages/hppbottom (5).webp",
  "/images/hppDressUpImages/hppbottom (6).webp",
  "/images/hppDressUpImages/hppbottom (1).webp",

];

const HPP_DressUp = () => {
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const removeModel = (bool) => {
    setModel(bool);
    setTempImgSrc("");
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>
          Craftsman - HPP & Dress Up Services - International Retail Specialists
        </title>
        <meta
          name="description"
          content="Discover our compact yet effective HPP and Dress Up solutions, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!"
        />

        <meta
          property="og:title"
          content="Craftsman - HPP & Dress Up Services - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Discover our compact yet effective HPP and Dress Up solutions, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!"
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta
          property="og:url"
          content="https://craftsman-llc.com/hpp-dressups"
        />
        <meta property="og:type" content="website" />

        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div
        onClick={() => removeModel(false)}
        className={model ? "model open" : "model"}
      >
        <img loading="lazy" src={tempImgSrc} />
        <div onClick={() => removeModel(false)}>
          <Close fill={"#fff"} />
        </div>
      </div>
      <div className="podium-container">
        <Layout />
        <div className="flex container-xl mx-auto flex-col">
          <h2 className="capitalize fs-7 mb-5">HPP & Dress Up's</h2>
          <div className="flex-col gap-10">
            {podiumsData.slides.map((slide, index) => (
              <PodiumSection slide={slide} index={index} />
            ))}
          </div>
          <ImageGrid isButton={false}>
            {images.map((src, index) => (
              <img
                key={index}
                className="pointer"
                onClick={() => getImg(src)}
                src={src}
                alt={`Dress up ${index + 1}`}
              />
            ))}
          </ImageGrid>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default HPP_DressUp;
